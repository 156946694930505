<template>
  <div class="surplus-store">
    <div class="top-operation pb20">
      <div class="left-operation">
        <a-button
          id="supplus_store_add_btn"
          class="supplus-btn new-supplus"
          style="width: 126px; border: none"
          @click="handleShowAddSurS"
          :title="t('createNewSurplusStore')"
        >
          <span class="iconfont icon-add"></span>
          <!-- 新建余料库 -->
          <g-t-view :text="t('createNewSurplusStore')" width="100px" />
        </a-button>
        <a-button id="supplus_store_set_btn" @click="handleShowStorageSetting">
          <!-- 余料入库设置 -->
          {{ t('pushStoreSetting') }}
        </a-button>
      </div>
      <div class="right-filter">
        <a-input-search
          v-model="storeKeyWord"
          :placeholder="$t('common.inputPlaceholder')"
          @search="getSurplusStoreList()"
          @change="handleChange"
          id="supplus_store_search_input"
          allowClear
        >
          <a-select
            slot="addonBefore"
            v-model="storeSearchType"
            style="width: 90px"
            id="supplus_store_search_type_select"
          >
            <a-select-option
              id="supplus_store_search_type_select_name"
              value="name"
            >
              <!-- 余料库名 -->
              {{ t('surplusStoreName') }}
            </a-select-option>
          </a-select>
        </a-input-search>
      </div>
    </div>
    <a-table
      :columns="surplusStoreColumns"
      :data-source="surplusStoreArr"
      :row-key="
        (record, index) => {
          return index
        }
      "
      :scroll="{
        y: 'calc(100vh - 64px - 40px - 52px - 20px - 170px)',
      }"
      :pagination="false"
      class="surplus-store-table"
    >
      <template slot="selectTitle">
        <a-checkbox
          v-model="isSelectAllStore"
          id="supplus_store_list_all_checkbox"
          @change="selectAllSurplusStore()"
        ></a-checkbox>
      </template>
      <template slot="select" slot-scope="text, record, index">
        <a-checkbox
          v-model="record.isSelected"
          class="ml20"
          :id="`supplus_store_table_${index}_select_checkbox`"
          @change="handleSelectedSurplusStore(record)"
        ></a-checkbox>
      </template>
      <template slot="rules" slot-scope="text">
        <span :title="handleShowRules(text)">{{ handleShowRules(text) }}</span>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding-right: 15px;
          "
        >
          <span
            :id="`supplus_store_table_${index}_detail_btn`"
            @click="handleGoDetail(record)"
            class="table-operation-btn"
            style="margin-left: 30px"
          >
            <!-- 详情 -->
            <g-t-view :text="$t('common.detail')" width="28px"></g-t-view>
          </span>
          <span
            :id="`supplus_store_table_${index}_edit_btn`"
            @click="handleShowEditSurS(record)"
            class="table-operation-btn"
          >
            <!-- 编辑 -->
            <g-t-view :text="$t('common.edit')" width="28px"></g-t-view>
          </span>
          <span
            :id="`supplus_store_table_${index}_del_btn`"
            @click="handleShowDelSurS(record)"
            class="table-operation-btn"
          >
            <!-- 删除 -->
            <g-t-view :text="$t('common.delete')" width="28px"></g-t-view>
          </span>
        </div>
      </template>
    </a-table>

    <div class="supplus-pagination absolute b5">
      <div>
        <span class="color-o-6 mr15">
          <!-- 已选数据 -->
          {{
            $t('common.chooseAmountData', {
              number: selectedSurplusStoreList.length + '',
            })
          }}
        </span>
        <a-button
          @click="handleShowDelSurS({}, true)"
          :disabled="!selectedSurplusStoreList.length"
          id="supplus_store_delete-btn"
        >
          <!-- 批量删除 -->
          {{ $t('common.batchDelete') }}
        </a-button>
      </div>
      <div>
        <a-pagination
          v-model="page"
          :page-size-options="pageSizeOptions"
          :total="totalSupplus"
          show-size-changer
          :page-size="pageSize"
          @showSizeChange="onShowSizeChange"
          @change="changeCurrentPage"
          :show-total="
            (totalCount) =>
              $t('common.paginationTotalTip', {
                current: (page - 1) * pageSize + 1,
                page: page * pageSize,
                total: totalCount,
              })
          "
        >
          <template slot="buildOptionText" slot-scope="props">
            <span v-if="props.value != totalSupplus"
              >{{ props.value }}{{ $t('common.strip') }}/{{
                $t('common.page')
              }}</span
            >
            <span v-if="props.value == totalSupplus">
              <!-- 全部 -->
              {{ $t('common.all') }}
            </span>
          </template>
        </a-pagination>
        <div class="jump-to-page">
          <span>
            <!-- 跳至 -->
            {{ t('jumpTo') }}
          </span>
          <a-input
            @pressEnter="jumpToPage"
            @input="pageInputCheck"
            v-model="jumpPage"
            id="supplus_store_pagination_input"
          ></a-input>
          <span>
            {{ $t('common.page') }}
          </span>
        </div>
      </div>
    </div>

    <!-- 新建/编辑余料库抽屉 -->
    <a-drawer
      :title="$translateLang(surplusStoreModalTitle)"
      placement="right"
      :visible="isAddSurplusStore"
      width="522"
      class="add-surStore-drawer"
      @close="handleCloseSurStoreDrawer"
    >
      <div class="drawer-div relative">
        <div class="drawer-label" :title="t('surplusStoreName2')">
          <span class="drawer-required">*</span>
          <span class="color-o-6">
            <!-- 余料库名称 -->
            {{ t('surplusStoreName2') }}：
          </span>
        </div>
        <a-input
          v-model="surplusStoreData.name"
          class="ml8"
          style="width: 386px"
          @input="handleInputCheck('name')"
          @blur="handleInputCheck('name')"
          :class="inputAlert.name ? 'alert-input' : ''"
          id="surplus_store_name_input"
          :placeholder="$t('common.inputPlaceholder')"
        ></a-input>
        <transition name="errorInfo">
          <span v-show="inputAlert.name" class="error-info">{{
            $translateLang(inputAlert.name)
          }}</span>
        </transition>
      </div>
      <div class="drawer-div relative">
        <div class="drawer-label" :title="t('inventoryCeiling')">
          <span class="drawer-required">*</span>
          <span class="color-o-6">
            <!-- 库存上限 -->
            {{ t('inventoryCeiling') }}：
          </span>
        </div>
        <a-input
          v-model="surplusStoreData.capacity"
          class="ml8"
          style="width: 386px"
          @input="handleInputCheck('capacity')"
          @blur="handleInputCheck('capacity')"
          :class="inputAlert.capacity ? 'alert-input' : ''"
          id="surplus_store_capacity_input"
          :placeholder="$t('common.inputPlaceholder')"
        ></a-input>
        <transition name="errorInfo">
          <span v-show="inputAlert.capacity" class="error-info">{{
            $translateLang(inputAlert.capacity)
          }}</span>
        </transition>
      </div>
      <div class="drawer-div flex-cross--top">
        <div class="drawer-label">
          <span class="color-o-6">
            <!-- 备注 -->
            {{ $t('common.remark') }}：
          </span>
        </div>
        <a-textarea
          v-model="surplusStoreData.remark"
          id="supplus_store_remark_input"
          :placeholder="$t('common.inputPlaceholder')"
          class="ml8"
          style="width: 386px; height: 56px"
        ></a-textarea>
      </div>
      <!-- 入库规则 -->
      <div class="rules mt4">
        <NewContent
          :content="$translateLang('限免')"
          class="surplus-rules-limit"
        >
          <p class="color-o-9 mb4">
            {{ $translateLang('余料入库规则') }}
          </p>
        </NewContent>
        <p class="tip">
          *{{
            $translateLang(
              '主规则之间是或的关系，即只要满足一个主规则，余料就会入库'
            )
          }}
        </p>
        <a-button
          id="supplus_store_add_main-rules"
          class="add-main-rule-btn mr4"
          style="width: 112px"
          @click="handleAddMainRule"
          :title="
            surplusStoreData.surplus_rules.length == 5
              ? $translateLang('主规则已达上限')
              : t('addMainRule')
          "
          :disabled="surplusStoreData.surplus_rules.length == 5"
          v-bp="'supplus_storage:add_main_rules'"
        >
          <span class="iconfont icon-add mr2"></span>
          <!-- 添加主规则 -->
          <g-t-view :text="t('addMainRule')" width="80px" />
        </a-button>
        <a-tooltip placement="right" overlayClassName="rule-tip main-rule-tip">
          <template #title>{{
            $translateLang(
              '添加规则后，命中规则的余料会优先存放到该仓库，该仓库满后会存入其他空闲余料仓'
            )
          }}</template>
          <img src="@/assets/help-circle.png" alt="" />
        </a-tooltip>
        <div class="rules-wrapper">
          <div
            class="main-rule mt16 br2"
            v-for="(item, index) in surplusStoreData.surplus_rules"
            :key="`main_rule_${index}`"
          >
            <div class="title mb14 flex flex-main--justify">
              <span>{{
                $translateLang('规则') +
                (currentLang == 'zh' ? numberMap[index] : index + 1)
              }}</span>
              <div class="right-btn flex flex-cross--center">
                <span class="mr16" @click="handleShowDelMRModal(index)">{{
                  $translateLang('删除')
                }}</span>
                <span
                  class="mr4"
                  @click="handleAddSubRule(index)"
                  v-show="item.length != 3"
                  v-bp="'supplus_storage:add_sub_rules'"
                  >{{ $translateLang('添加子规则') }}</span
                >
                <a-tooltip placement="bottomRight" overlayClassName="rule-tip">
                  <template #title>{{
                    $translateLang(
                      '子规则之间是且的关系，即需要同时满足主规则下的所有子规则，才会入库'
                    )
                  }}</template>
                  <img src="@/assets/help-circle.png" alt="" />
                </a-tooltip>
              </div>
            </div>
            <div
              class="sub-rule flex flex-cross--center relative"
              v-for="(subRule, subIndex) in item"
              :key="`sub_rule_${subIndex}`"
            >
              <a-select
                :value="subRule.attr"
                style="width: 102px"
                class="mr8"
                @change="handleChangeRuleType($event, subRule)"
              >
                <a-select-option
                  v-for="item in subRuleTypeList"
                  :key="item.value"
                  :value="item.value"
                  :disabled="
                    surplusStoreData.surplus_rules[index]
                      .map((subRuleItem) => subRuleItem.attr)
                      .includes(item.value)
                  "
                  >{{ $translateLang(item.label) }}</a-select-option
                >
              </a-select>
              <a-select
                :value="subRule.opt"
                style="width: 102px"
                v-if="subRule.attr == 'thick'"
                @change="handleChangeOpe($event, subRule)"
              >
                <a-select-option
                  v-for="item in operatorList"
                  :key="item.value"
                  :value="item.value"
                  >{{ $translateLang(item.label) }}</a-select-option
                >
              </a-select>
              <span v-else class="operator-show">{{
                $translateLang('等于')
              }}</span>
              <!-- copy 的新建特殊板材的板材材质 -->
              <el-select
                :value="subRule.value"
                filterable
                clearable
                id="surplus_store_sub_rule_plank_matcode_select"
                style="width: 102px; height: 32px"
                class="mh8"
                :placeholder="$t('common.inputPlaceholder')"
                v-show="subRule.attr == 'matCode'"
                @change="handleSelectMatCode($event, subRule)"
                @keydown.native.enter="handleEnterMatCode($event, subRule)"
              >
                <el-option
                  v-for="(item, index) in matcodeList"
                  :key="'type' + index"
                  :value="item.value"
                  :label="item.label"
                  :id="`surplus_store_sub_rule_plank_matcode_select_${index}`"
                ></el-option>
              </el-select>
              <transition name="errorInfo">
                <span
                  v-show="subRule.inputAlert"
                  class="error-info subRule-error-info"
                  >{{ $translateLang(subRule.inputAlert) }}</span
                >
              </transition>
              <!-- 大板厚度输入框 -->
              <a-input
                v-model="subRule.value"
                v-show="subRule.attr == 'thick'"
                style="width: 212px"
                class="mh8"
                :placeholder="$t('common.inputPlaceholder')"
                @input="handleCheckThick(subRule)"
              ></a-input>
              <!-- copy 的新建特殊板材的板材颜色 -->
              <el-cascader
                v-loading="isLoadingColors"
                id="surplus_store_sub_rule_create_plank_color_input"
                v-model="subRule.value"
                :options="materialColorOptions"
                @change="handleSelectTexture($event, subRule)"
                @keydown.enter.native="handleEnterColor($event, subRule)"
                ref="addColorCascaderRef"
                :show-all-levels="false"
                filterable
                clearable
                :placeholder="$t('common.colorPlaceHolder')"
                v-show="subRule.attr == 'texture'"
                style="width: 212px"
                class="mh8"
              />
              <a-select
                :value="subRule.isHigh"
                style="width: 102px"
                class="mr8"
                v-show="subRule.attr == 'matCode'"
                @change="handleChangeHigh($event, subRule)"
              >
                <a-select-option
                  v-for="item in highPlankList"
                  :key="`high_plank_${item.value}`"
                  :value="item.value"
                  >{{ $translateLang(item.label) }}</a-select-option
                >
              </a-select>
              <i
                class="iconfont el-icon-delete cursor-pointer"
                v-show="item.length > 1"
                @click="handleDelSubRule(index, subIndex)"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-footer fixed b0 pv16">
        <a-button
          style="width: 114px"
          class="mr16"
          @click="handleCloseSurStoreDrawer"
          >{{ $translateLang('取消') }}</a-button
        >
        <a-button
          style="width: 360px"
          class="save-btn"
          @click="handleUpdateSurS"
          >{{ $translateLang('保存') }}</a-button
        >
      </div>
    </a-drawer>
    <!-- 删除弹窗 -->
    <g-base-modal
      :visible="showDelSurplusStore"
      v-if="showDelSurplusStore"
      @ok="handleDelSurS"
      @cancel="showDelSurplusStore = false"
      :ok-text="$t('common.confirmDelete')"
      :contain="t('autoTransferTip')"
      :preffix="`surplus_store_${isBatchDel ? 'batch_del' : 'del'}`"
    >
    </g-base-modal>
    <!-- 余料入库设置 -->
    <g-base-modal
      :visible="showStorageSetting"
      v-if="showStorageSetting"
      :showIcon="false"
      :title="t('pushStoreSetting')"
      :ok-text="$t('common.confirm')"
      :isLoading="showLoading"
      @cancel="showStorageSetting = false"
      @ok="handleStorageSetting"
    >
      <a-radio-group
        v-model="checkedStorageSetting"
        style="display: flex; flex-direction: column"
      >
        <a-radio style="margin-bottom: 26px" :value="true">
          <!-- 按照仓库存放情况顺序入库 -->
          {{ t('asIndexPushStore') }}
        </a-radio>
        <a-radio style="margin-left: 0" :value="false">
          <!-- 存入余料清单 -->
          {{ t('pushToSurplusMaterial') }}
        </a-radio>
      </a-radio-group>
    </g-base-modal>
    <!-- 删除主规则弹窗 -->
    <g-base-modal
      :visible="showDelMainRule"
      v-if="showDelMainRule"
      @ok="handleConfirmDelMR"
      @cancel="showDelMainRule = false"
      :ok-text="$t('common.confirmDelete')"
      :contain="$t('surplusPage.delMainRuleTip')"
      preffix="surplus_store_del_main_rule"
    ></g-base-modal>
  </div>
</template>

<script>
import { buryPoint } from '@/apis/common/buryPoint'
import { batchSaveSurWar } from '@/apis/surplusManage/index.ts'
import GBaseModal from '@/components/g-base-modal.vue'
import NewContent from '@/components/newContent.vue'
import GTView from '@/components/translateComp/g-t-view.vue'
import store from '@/store'
import { EventBusKey } from '@/util/bus'
import EventBus from '@/util/bus'
import {
  checkColorIsExist,
  checkMatCodeIsExist,
  fetchMaterialMatCodes,
  translate,
} from '@/util/commonFun'
import { genFinalRecord } from '@/util/commonFuncs.js'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    GBaseModal,
    GTView,
    NewContent,
  },
  data() {
    return {
      // 记录是否显示加载
      showLoading: false,
      // 余料库
      isAddSurplusStore: false, // 新建余料库
      surplusStoreModalTitle: '新建余料库',
      // 新建/编辑余料库数据
      surplusStoreData: {
        name: '',
        capacity: '',
        remark: '',
        surplus_rules: [],
      },
      copySurStoreData: {},
      showDelSurplusStore: false,
      showStorageSetting: false,
      checkedStorageSetting: false,
      oriCheckedStorageSetting: false,
      checkedStorageSettingTemp: false,
      storeKeyWord: '',
      storeSearchType: 'name',
      isSelectAllStore: false,
      selectedSurplusStoreList: [],
      currentClickSurplusStore: {},
      // 记录当前页码
      page: 1,
      // 记录输入的跳转页码
      jumpPage: '',
      // 记录当前一页显示条数
      pageSize: 10,
      // 记录可切换的pageSize
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      // 记录总条数
      totalSupplus: 1,
      isDeleteSurS: false,
      surplusStoreArr: [],
      surplusStoreColumns: [
        {
          dataIndex: 'select',
          key: 'select',
          width: 40,
          fixed: 'left',
          scopedSlots: { customRender: 'select' },
          slots: { title: 'selectTitle' },
        },
        {
          dataIndex: 'branch_no',
          key: 'branch_no',
          title: '编号',
          width: 80,
          align: 'center',
          translateKey: 'surplusPage.surplusStoreTable.number',
        },
        {
          dataIndex: 'name',
          key: 'name',
          title: '余料库名称',
          width: 120,
          ellipsis: true,
          align: 'center',
          translateKey: 'surplusPage.surplusStoreTable.name',
        },
        {
          dataIndex: 'quantity',
          key: 'quantity',
          title: '已存余料数量(个)',
          width: 120,
          align: 'center',
          ellipsis: true,
          translateKey: 'surplusPage.surplusStoreTable.quantity',
        },
        {
          dataIndex: 'capacity',
          key: 'capacity',
          title: '余料库存上限(个)',
          width: 120,
          align: 'center',
          ellipsis: true,
          translateKey: 'surplusPage.surplusStoreTable.capacity',
        },
        {
          dataIndex: 'surplus_rules',
          key: 'surplus_rules',
          title: '余料入库规则',
          width: 120,
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'rules' },
          translateKey: 'surplusPage.surplusStoreTable.rules',
        },
        {
          dataIndex: 'remark',
          key: 'remark',
          title: '备注',
          width: 180,
          ellipsis: true,
          align: 'center',
          translateKey: 'surplusPage.surplusStoreTable.remark',
        },
        {
          dataIndex: 'operation',
          key: 'operation',
          title: '操作',
          width: 100,
          scopedSlots: { customRender: 'operation' },
          translateKey: 'surplusPage.surplusStoreTable.operation',
        },
      ],
      // 新建/编辑输入框警示
      inputAlert: {
        name: '',
        capacity: '',
      },
      inputAlertMap: {
        name: '余料库名称',
        capacity: '库存上限',
        matCode: '大板材质',
        thick: '大板厚度',
        texture: '大板颜色',
      },
      // 是否是批量删除
      isBatchDel: false,
      // 判断是否存在筛选
      isFilterStatus: false,
      subRuleTypeList: [
        {
          value: 'matCode',
          label: '大板材质',
        },
        {
          value: 'thick',
          label: '大板厚度',
        },
        {
          value: 'texture',
          label: '大板颜色',
        },
      ],
      operatorList: [
        {
          value: '==',
          label: '等于',
        },
        {
          value: '>',
          label: '大于',
        },
        {
          value: '<',
          label: '小于',
        },
        {
          value: '>=',
          label: '大于等于',
        },
        {
          value: '<=',
          label: '小于等于',
        },
      ],
      matcodeList: [],
      highPlankList: [
        {
          value: 0,
          label: '非高光板',
        },
        {
          value: 1,
          label: '高光板',
        },
      ],
      materialColorOptions: [],
      numberMap: ['一', '二', '三', '四', '五'],
      showDelMainRule: false,
      delMainRuleIndex: null,
    }
  },
  computed: {
    ...mapState(['materialColorList', 'isLoadingColors']),
    currentLang() {
      return store.state.preferencesSetting.setting.lang
    },
  },
  watch: {
    totalSupplus: {
      handler() {
        this.updateTransEmpty()
      },
    },
    materialColorList: {
      handler(val) {
        this.materialColorOptions = val
      },
    },
  },
  methods: {
    ...mapMutations(['setBaseMaterialColorList', 'setBaseMaterialMatCode']),
    handleChange(e) {
      let timer = null
      if (!e.target.value) {
        if (timer !== null) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          this.page = 1 //time用来控制事件的触发
          this.getSurplusStoreList()
        }, 500)
      }
    },
    handleShowAddSurS() {
      this.isAddSurplusStore = true
      this.surplusStoreModalTitle = '新建余料库'
      this.surplusStoreData = {
        name: '',
        capacity: '',
        remark: '',
        surplus_rules: [],
      }
      this.copySurStoreData = JSON.parse(JSON.stringify(this.surplusStoreData))
      Object.keys(this.inputAlert).forEach((key) => {
        this.inputAlert[key] = ''
      })
    },
    // 查看详情 跳转到余料清单 携带当前余料库
    handleGoDetail(surplus) {
      this.$emit('onGoDetail', surplus)
    },
    handleShowEditSurS(surplus) {
      this.isAddSurplusStore = true
      this.surplusStoreModalTitle = '编辑余料库'
      this.surplusStoreData = JSON.parse(JSON.stringify(surplus))
      this.copySurStoreData = JSON.parse(JSON.stringify(surplus))
      Object.keys(this.inputAlert).forEach((key) => {
        this.inputAlert[key] = ''
      })
    },
    handleShowDelSurS(surplus, isBatchDel) {
      this.isBatchDel = isBatchDel
      this.showDelSurplusStore = true
      if (Object.keys(surplus).length) {
        this.surplusStoreData = surplus
      }
    },
    handleUpdateSurS() {
      // 判断是否可以保存
      let ableSave = this.checkInputIsBlank()
      if (!ableSave) return
      this.isAddSurplusStore = false
      if (
        this.surplusStoreModalTitle.includes('编辑') &&
        this.copySurStoreData.name == this.surplusStoreData.name &&
        this.copySurStoreData.capacity == this.surplusStoreData.capacity &&
        this.copySurStoreData.remark == this.surplusStoreData.remark &&
        JSON.stringify(this.copySurStoreData.surplus_rules) ==
          JSON.stringify(this.surplusStoreData.surplus_rules)
      ) {
        return
      }
      const params = {
        surplus_branch_warehouses: [
          {
            ...this.surplusStoreData,
            capacity: Number(this.surplusStoreData.capacity),
            branch_no: this.surplusStoreData.branch_no ?? '',
          },
        ],
        create_or_update: this.surplusStoreModalTitle.includes('新建')
          ? true
          : false,
      }
      batchSaveSurWar(params).then((res) => {
        if (res.status == 1) {
          this.$message.success(
            `${
              this.surplusStoreModalTitle.includes('新建') ? '新建' : '编辑'
            }成功`
          )
          this.getSurplusStoreList()
          // 埋点及操作记录
          buryPoint({
            function_module: 'supplus_storage',
            function_point: this.surplusStoreModalTitle.includes('新建')
              ? 'add_surplus_warehouse'
              : 'edit_surplus_warehouse',
          })
          if (
            this.surplusStoreModalTitle.includes('编辑') &&
            JSON.stringify(this.copySurStoreData.surplus_rules) !=
              JSON.stringify(this.surplusStoreData.surplus_rules)
          ) {
            buryPoint({
              function_module: 'supplus_storage',
              function_point: 'edit_surplus_rules1',
            })
          }
          let operateArr = []
          if (
            !this.surplusStoreModalTitle.includes('新建') &&
            this.copySurStoreData.capacity != this.surplusStoreData.capacity
          ) {
            operateArr.push({
              key: '库存上限修改',
              dataArr: [
                {
                  type: 'form',
                  current: { capacity: this.surplusStoreData.capacity },
                  ori: { capacity: this.copySurStoreData.capacity },
                  compareMsg: [
                    {
                      title: '',
                      keys: ['capacity'],
                    },
                  ], // 要比较的内容
                  formTextObj: {
                    capacity: '库存上限',
                  }, // key对应的意思
                  formValueObj: {}, // value对应的意思
                },
              ],
            })
          }
          const rulesLength = this.surplusStoreData.surplus_rules.reduce(
            (acc, current) => acc + current.length,
            0
          )
          const copyRulesLength = this.copySurStoreData.surplus_rules.reduce(
            (acc, current) => acc + current.length,
            0
          )
          if (
            JSON.parse(JSON.stringify(this.surplusStoreData.surplus_rules)) !=
            JSON.parse(JSON.stringify(this.copySurStoreData.surplus_rules))
          ) {
            operateArr.push({
              key: '余料入库规则',
              dataArr: [
                {
                  type: 'form',
                  current: {
                    rules: this.handleShowRules(
                      this.surplusStoreData.surplus_rules
                    ),
                  },
                  ori: {
                    rules: this.handleShowRules(
                      this.copySurStoreData.surplus_rules
                    ),
                  },
                  compareMsg: [
                    {
                      title: '',
                      keys: ['rules'],
                    },
                  ], // 要比较的内容
                  formTextObj: {
                    rules:
                      rulesLength > copyRulesLength
                        ? '添加'
                        : rulesLength < copyRulesLength
                        ? '删除'
                        : '编辑',
                  }, // key对应的意思
                  formValueObj: {}, // value对应的意思
                },
              ],
            })
          }
          genFinalRecord(
            {
              key: this.surplusStoreData.name,
              operateArr,
            },
            '余料库'
          )
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleDelSurS() {
      const params = {
        branch_nos: this.isBatchDel
          ? this.selectedSurplusStoreList.map((item) => item.branch_no)
          : [this.surplusStoreData.branch_no],
      }
      this.$delByToken('/surplus/branch_warehouses', params, (res) => {
        if (res.status == 1) {
          this.$message.success('删除成功')
          this.selectedSurplusStoreList = []
          this.isSelectAllStore = false
          if (
            this.page >
              Math.ceil(
                (this.totalSupplus - params.branch_nos.length) / this.pageSize
              ) &&
            this.page > 1
          ) {
            this.page = this.page - 1
          }
          this.getSurplusStoreList(true)
        } else {
          this.$message.error('删除失败')
        }
      })
      this.showDelSurplusStore = false
    },
    handleShowStorageSetting() {
      this.showStorageSetting = true
      this.oriCheckedStorageSetting = this.checkedStorageSetting
    },
    handleStorageSetting() {
      this.$token(
        '/surplus/branch_warehouses/setting',
        { sequential_warehousing: this.checkedStorageSetting },
        (res) => {
          if (res.status == 1) {
            this.checkedStorageSettingTemp = this.checkedStorageSetting
            this.$message.success('设置成功')
            genFinalRecord(
              {
                key: '',
                operateArr: [
                  {
                    key: '',
                    dataArr: [
                      {
                        type: 'form',
                        current: {
                          storeSetting: this.checkedStorageSetting
                            ? '按照仓库存放情况顺序入库'
                            : '存入余料清单',
                        },
                        ori: {
                          storeSetting: this.oriCheckedStorageSetting
                            ? '按照仓库存放情况顺序入库'
                            : '存入余料清单',
                        },
                        compareMsg: [
                          {
                            title: '',
                            keys: ['storeSetting'],
                          },
                        ], // 要比较的内容
                        formTextObj: {
                          storeSetting: '余料入库设置',
                        }, // key对应的意思
                        formValueObj: {}, // value对应的意思
                      },
                    ],
                  },
                ],
              },
              '余料库'
            )
          } else {
            // 如果设置失败，按钮不改变
            this.checkedStorageSetting = this.checkedStorageSettingTemp
            this.$message.error(res.msg)
          }
        }
      )
      this.showStorageSetting = false
    },
    // 全选余料库
    selectAllSurplusStore() {
      let status = true
      if (!this.isSelectAllStore) {
        status = false
      }
      for (let i = 0; i < this.surplusStoreArr.length; ++i) {
        this.surplusStoreArr[i].isSelected = status
      }
      this.selectedSurplusStoreList = this.surplusStoreArr.filter(
        (item) => item.isSelected
      )
      this.$forceUpdate()
    },
    handleClickSupplusStore(supplus) {
      this.currentClickSurplusStore = supplus
    },
    // 选择余料库处理
    handleSelectedSurplusStore() {
      let flag = true
      for (let i = 0; i < this.surplusStoreArr.length; ++i) {
        if (!this.surplusStoreArr[i].isSelected) {
          flag = false
          break
        }
      }
      if (flag) {
        this.isSelectAllStore = true
      } else {
        this.isSelectAllStore = false
      }
      this.selectedSurplusStoreList = this.surplusStoreArr.filter(
        (item) => item.isSelected
      )
    },
    // 获取余料库数据
    getSurplusStoreList(isDel, page) {
      if (this.storeKeyWord != '') {
        this.isFilterStatus = true
        this.page = 1
      }
      const params = {
        search_key: this.storeSearchType,
        search_val: this.storeKeyWord,
        page_no: page || this.page,
        page_size: this.pageSize,
      }
      this.$getByToken('/surplus/branch_warehouses', params, (res) => {
        if (res.status == 1) {
          this.surplusStoreArr = res.data.branch_warehouses
          this.totalSupplus = res.data.total
          if (isDel && this.checkedStorageSetting && res.data.total == 0) {
            this.checkedStorageSetting = false
            this.$token(
              '/surplus/branch_warehouses/setting',
              {
                sequential_warehousing: this.checkedStorageSetting,
              },
              (setRes) => {
                if (setRes.status == 1) {
                  genFinalRecord(
                    {
                      key: '',
                      operateArr: [
                        {
                          key: '',
                          dataArr: [
                            {
                              type: 'form',
                              current: {
                                storeSetting: '存入余料清单',
                              },
                              ori: {
                                storeSetting: '按照仓库存放情况顺序入库',
                              },
                              compareMsg: [
                                {
                                  title: '',
                                  keys: ['storeSetting'],
                                },
                              ], // 要比较的内容
                              formTextObj: {
                                storeSetting: '余料入库设置',
                              }, // key对应的意思
                              formValueObj: {}, // value对应的意思
                            },
                          ],
                        },
                      ],
                    },
                    '余料库'
                  )
                }
              }
            )
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 是否显示tooltip
    showTootip(value, surplus, prop) {
      let dom = this.$refs[`surplus_${surplus.branch_no}_${prop}`][0]
      if (dom) {
        if (dom.offsetWidth < dom.scrollWidth) {
          this.$set(surplus, `show${prop}`, true)
        } else {
          this.$set(surplus, `show${prop}`, false)
        }
        if (!value) {
          surplus[`show${prop}`] = false
        }
      }
    },
    // 切换每页显示条数
    onShowSizeChange(val, pageSize) {
      this.pageSize = pageSize
      this.getSurplusStoreList(false, val)
    },
    // 切换页码
    changeCurrentPage() {
      this.isSelectAllStore = false
      this.getSurplusStoreList()
    },
    // 页码输入限制
    pageInputCheck() {
      this.jumpPage = this.jumpPage.replace(/^(0+)|[^\d]+/g, '')
    },
    // 跳转至指定页
    jumpToPage() {
      if (this.jumpPage != '') {
        this.page = Number(this.jumpPage)
        this.getSurplusStoreList()
      }
    },
    // 新建/编辑输入框弹窗检测
    handleInputCheck(type) {
      if (type == 'capacity' && this.dealDecimal(type)) {
        this.inputAlert[type] = `${this.inputAlertMap[type]}只能输入整数!`
      } else if (
        this.surplusStoreData[type] === '' ||
        Number(this.surplusStoreData[type]) == 0
      ) {
        this.inputAlert[type] = `${this.inputAlertMap[type]}不能为空!`
      } else {
        this.inputAlert[type] = ''
      }
      this.surplusStoreData.name = this.surplusStoreData.name.slice(0, 20)
    },
    dealDecimal(type) {
      let reg = /[^\d]/
      let flag = false
      if (reg.test(this.surplusStoreData[type])) {
        flag = true
      }
      this.surplusStoreData[type] = this.surplusStoreData[type]
        .toString()
        .replace(/\./g, '')
        .slice(0, 6)
      return flag
    },
    // 判断输入是否为空
    checkInputIsBlank() {
      let ableSave = true
      Object.keys(this.surplusStoreData).forEach((key) => {
        if (
          (key == 'name' || key == 'capacity') &&
          (this.surplusStoreData[key] === '' ||
            Number(this.surplusStoreData[key]) == 0)
        ) {
          this.inputAlert[key] = `${this.inputAlertMap[key]}不能为空!`
          ableSave = false
        }
        if (key == 'surplus_rules') {
          this.surplusStoreData.surplus_rules.forEach((item) => {
            item.forEach((subItem) => {
              if (subItem.value === '' || Number(subItem.value) == 0) {
                this.$set(
                  subItem,
                  'inputAlert',
                  `${this.inputAlertMap[subItem.attr]}不能为空!`
                )
                ableSave = false
              } else {
                delete subItem.inputAlert
              }
            })
          })
        }
      })
      return ableSave
    },
    // 获取余料分仓设置
    handleGetSurWarSet() {
      this.$getByToken('/surplus/branch_warehouses/setting', {}, (res) => {
        if (res.status == 1) {
          this.checkedStorageSetting = res.data.sequential_warehousing
          this.checkedStorageSettingTemp = res.data.sequential_warehousing
        } else {
          this.$message.error('获取余料入库失败')
        }
      })
    },
    t(k) {
      return translate('surplusPage.' + k)
    },
    // 翻译余料库表格表头列
    updateTransSurplusTable() {
      this.surplusStoreColumns = this.surplusStoreColumns.map((item) => {
        if (!['select'].includes(item.key)) {
          return {
            ...item,
            title: translate(item.translateKey),
            // 自定义头部单元格
            customHeaderCell: () => ({
              attrs: {
                // 可以在这里添加额外的属性，比如 title
                title: translate(item.translateKey),
              },
            }),
          }
        } else {
          return item
        }
      })
    },
    updateTransEmpty() {
      if (this.totalSupplus == 0) {
        this.$nextTick(() => {
          const emptyDom = document.querySelector(
            '.surplus-store-table .ant-empty-description'
          )
          if (emptyDom) {
            if (!this.isFilterStatus) {
              emptyDom.innerHTML = `${this.$t('common.notDataTipByType', {})}
            <span
              id="supplus_store_table_add_btn"
              style="color: #18a8c7; cursor: pointer"
            >${this.$t('surplusPage.addSurplusStore')}</span>
          `
            } else {
              // 未查询到相关数据
              emptyDom.innerHTML = this.$t('surplusPage.noMatchSup')
            }
          }
          const addBtn = document.querySelector('#supplus_store_table_add_btn')
          if (addBtn) {
            addBtn.addEventListener('click', this.handleShowAddSurS)
          }
        })
      }
    },
    // 关闭 新建/编辑余料库抽屉
    handleCloseSurStoreDrawer() {
      this.isAddSurplusStore = false
    },
    // 添加主规则
    handleAddMainRule() {
      if (this.surplusStoreData.surplus_rules.length < 5) {
        this.surplusStoreData.surplus_rules.push([
          { attr: 'matCode', opt: '==', value: '', isHigh: 0 },
        ])
      }
    },
    // 显示删除主规则弹窗
    handleShowDelMRModal(index) {
      this.showDelMainRule = true
      this.delMainRuleIndex = index
    },
    // 确认删除主规则
    handleConfirmDelMR() {
      this.surplusStoreData.surplus_rules.splice(this.delMainRuleIndex, 1)
      this.showDelMainRule = false
    },
    // 添加子规则
    handleAddSubRule(index) {
      if (this.surplusStoreData.surplus_rules[index].length < 3) {
        let typeList = ['matCode', 'thick', 'texture']
        const currentTypeList = this.surplusStoreData.surplus_rules[index].map(
          (item) => item.attr
        )
        typeList = typeList.filter((type) => !currentTypeList.includes(type))
        let obj = {
          attr: typeList[0],
          opt: '==',
          value: '',
        }
        if (typeList[0] == 'matCode') {
          obj.isHigh = 0
        }
        this.surplusStoreData.surplus_rules[index].push(obj)
      }
    },
    // 子规则类型变化
    handleChangeRuleType(val, subRule) {
      subRule.attr = val
      subRule.value = ''
      subRule.opt = '=='
      if (val != 'matCode') {
        delete subRule.isHigh
      } else {
        this.$set(subRule, 'isHigh', 0)
      }
    },
    // 选择等式
    handleChangeOpe(val, subRule) {
      subRule.opt = val
    },
    // 选择大板材质
    handleSelectMatCode(val, subRule) {
      subRule.value = val
      if (val) {
        delete subRule.inputAlert
      }
    },
    // 按回车对材质进行的操作
    handleEnterMatCode(e, subRule) {
      // 编辑时使用subRule.value的值去判断
      const inputMatCode = e?.target?.value ?? subRule.value
      // 当为空或者全是空格时不进行操作
      if (!inputMatCode) return
      // 判断数组是否已经存在当前添加的数据
      const isExist = checkMatCodeIsExist(inputMatCode, this.matcodeList)
      // 获取本地的材质数据
      let otherMatCodes = localStorage.getItem('matcodeList')
        ? JSON.parse(localStorage.getItem('matcodeList'))
        : []
      if (!isExist) {
        // 如果不存在
        otherMatCodes.push(inputMatCode)
        localStorage.setItem('matcodeList', JSON.stringify(otherMatCodes))
        // 将数据添加到数组中，并且显示
        let matcodeSelectOption = {
          label: inputMatCode,
          value: inputMatCode,
        }
        this.matcodeList.push(matcodeSelectOption)
      }
      // 保证条目被选中
      subRule.value = inputMatCode
      this.setBaseMaterialMatCode(inputMatCode)
    },
    // 大板厚度 输入限制
    handleCheckThick(subRule) {
      subRule.value = subRule.value.replace(/[^\d\\.]/g, '')
      const part = subRule.value.split('.')
      // 最多两位小数
      if (part[1] && part[1].length > 2) {
        subRule.value = `${part[0]}.${part[1].substring(0, 2)}`
      }
      if (subRule.value > 100) {
        subRule.value = 100
      }
      if (subRule.value) {
        delete subRule.inputAlert
      }
    },
    async getPlankMatCode() {
      let tempList = await fetchMaterialMatCodes()
      this.matcodeList = tempList
    },
    // 选择大板颜色
    handleSelectTexture(val, subRule) {
      subRule.value = val ? val[val.length - 1] : ''
      if (subRule.value) {
        delete subRule.inputAlert
      }
      this.setBaseMaterialColorList(val)
    },
    // 大板颜色 回车操作
    handleEnterColor(e, subRule) {
      if (!e?.target?.value) return
      const isExist = checkColorIsExist(
        e?.target?.value,
        this.materialColorOptions
      )
      let otherColors = localStorage.getItem('colorList')
        ? JSON.parse(localStorage.getItem('colorList'))
        : []
      if (isExist) {
        subRule.value = e?.target?.value
      } else {
        otherColors.push(e?.target?.value)
        subRule.value = e?.target?.value
        localStorage.setItem('colorList', JSON.stringify(otherColors))
        const lastIndex = this.materialColorOptions.length - 1
        this.materialColorOptions[lastIndex].children.push({
          label: e?.target?.value,
          value: e?.target?.value,
        })
      }
      if (e?.target?.value) {
        this.setBaseMaterialColorList(['other', e?.target?.value])
      }
    },
    handleChangeHigh(val, subRule) {
      subRule.isHigh = val
    },
    // 删除子规则
    handleDelSubRule(index, subIndex) {
      if (this.surplusStoreData.surplus_rules[index].length > 1) {
        this.surplusStoreData.surplus_rules[index].splice(subIndex, 1)
      }
    },
    // 处理表格 余料入库规则 的显示
    handleShowRules(text) {
      let str = ''
      text.forEach((item, index) => {
        item.forEach((subItem, subIndex) => {
          const type = this.subRuleTypeList.find(
            (typeItem) => typeItem.value == subItem.attr
          )?.label
          const opt = this.operatorList.find(
            (opeItem) => opeItem.value == subItem.opt
          )?.label
          str += `${type}${opt}${subItem.value}`
          if (subItem.attr == 'matCode') {
            const isHigh = this.highPlankList.find(
              (highItem) => highItem.value == subItem.isHigh
            )?.label
            str += `(${isHigh})`
          }
          if (subIndex != item.length - 1) {
            str += '且'
          }
        })
        if (index != text.length - 1) {
          str += ',或'
        }
      })
      return str
    },
  },
  mounted() {
    this.getSurplusStoreList()
    this.handleGetSurWarSet()
    this.updateTransSurplusTable()
    EventBus.$on(EventBusKey.TRANSLATE, this.updateTransSurplusTable)
    EventBus.$on(EventBusKey.TRANSLATE, this.updateTransEmpty)
    this.getPlankMatCode()
    if (!this.materialColorOptions.length) {
      this.materialColorOptions = this.materialColorList
    }
  },
  unmounted() {
    EventBus.$off(EventBusKey.TRANSLATE, this.updateTransSurplusTable)
    EventBus.$off(EventBusKey.TRANSLATE, this.updateTransEmpty)
  },
}
</script>

<style scoped lang="less">
.surplus-store {
  .top-operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
  }
  .left-operation {
    display: flex;
    align-items: center;
  }

  .supplus-btn {
    display: flex;
    align-items: center;
    width: 110px;
    height: 32px;

    .iconfont {
      margin-right: 3px;
    }
  }

  .new-supplus {
    margin-right: 8px;
    color: #fff;
    background: #18a8c7;
  }

  .delete-btn-hover {
    color: #333;

    &:hover {
      color: #18a8c7;
    }
  }
  .right-filter {
    display: flex;
    align-items: center;

    .ant-select {
      width: 160px;
      margin-right: 8px;
    }

    .ant-input-search {
      width: 330px;
      margin-right: 8px;

      /deep/ .ant-input-group-addon {
        box-sizing: border-box;
        padding: 0;

        .ant-select.ant-select-enabled {
          width: 98px !important;
          margin-right: 0;
          margin-left: 1px;
        }
      }
    }

    .search-btn {
      width: 84px;
      color: #fff;
      background: #18a8c7;
      border: none;
    }

    .reset-btn {
      width: 82px;
      color: #333;
      background: #fafafa;

      &:hover {
        color: #18a8c7;
      }
    }
  }

  /deep/.surplus-store-table {
    .ant-table {
      border: 1px solid #dcdcdc;
      border-bottom: none;
      .ant-table-placeholder {
        border-bottom: 1px solid #dcdcdc;
      }
    }
    .ant-table-thead > tr {
      height: 40px !important;
      > th {
        height: 40px;
        padding: 0;
        background: #f5f5f5;
      }
      > th[key='select'] {
        .ant-table-header-column {
          padding-left: 20px;
        }
      }
      > th[key='operation'] {
        .ant-table-header-column {
          padding-left: 30px;
        }
      }
    }
    .ant-table-tbody > tr {
      position: relative;
      height: 40px !important;
      > td {
        height: 40px;
        padding: 0;
        border-bottom-color: #dcdcdc;
        .table-operation-btn {
          color: #18a8c7;
          cursor: pointer;
        }
      }
    }
  }

  .supplus-pagination {
    display: flex;
    justify-content: space-between;
    width: 98.3%;
    min-width: 1440px;
    height: 64px;
    margin-top: 30px;
    padding: 16px;
    background: #f3f3f3;

    & > div {
      display: flex;
      align-items: center;
    }

    .jump-to-page {
      display: flex;
      align-items: center;

      .ant-input {
        width: 48px;
      }

      span:first-child {
        margin-right: 8px;
      }

      span:last-child {
        margin-left: 8px;
      }
    }
  }
}
</style>

<style lang="less">
.add-surStore-drawer {
  .ant-drawer-header {
    padding: 18px 16px 24px 16px;
    border-bottom: none;
    .ant-drawer-title {
      color: #000;
    }
  }
  .ant-drawer-body {
    padding: 0 16px;
  }
  .drawer-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .drawer-label {
    width: 96px;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .drawer-required {
    margin-right: 1px;
    color: #f25e4a;
  }
  .error-info {
    position: absolute;
    top: 30px;
    left: 106px;
    color: #fd3232;
    font-size: 12px;
  }
  .subRule-error-info {
    left: 222px;
    top: 31px;
  }
  .alert-input {
    /deep/.ant-select-selection {
      border-color: #fd3232;
    }

    /deep/.ant-input {
      border-color: #fd3232;
    }

    &.ant-input {
      border-color: #fd3232;
    }
  }
  .rules-wrapper {
    height: 590px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rules {
    .surplus-rules-limit {
      .new-button_text {
        right: 0;
        top: 0;
        left: 90px;
        width: 36px;
        background-color: rgb(249, 224, 199);
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
    }
    .tip {
      color: #e34d59;
    }
    .add-main-rule-btn:not(:disabled) {
      border: 1px solid #18a8c7;
      > span {
        color: #18a8c7;
      }
    }
    .add-main-rule-btn {
      padding: 0 12px;
      border-radius: 3px;
      .icon-add {
        font-size: 12px;
      }
      > span {
        font-weight: 500;
      }
    }
    .main-rule {
      width: 490px;
      padding: 14px 14px 14px 12px;
      background-color: #f3f3f3;
      .right-btn {
        color: #18a8c7;
        > span {
          cursor: pointer;
        }
      }
    }
    .sub-rule {
      margin-bottom: 14px;
      .operator-show {
        display: inline-block;
        width: 102px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background-color: #fff;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
      }
      .el-input {
        height: 32px;
        .el-input__inner {
          height: 32px;
        }
        .el-select__caret {
          line-height: 32px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .drawer-footer {
    // box-shadow: 0 -1px 17.4px 0 #0000001a;
    .save-btn {
      background-color: #18a8c7;
      border: none;
      color: rgba(255, 255, 255, 0.9);
    }
  }
}
.rule-tip {
  .ant-tooltip-inner {
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.main-rule-tip {
  .ant-tooltip-content {
    width: 314px;
    height: 54px;
  }
}
</style>
